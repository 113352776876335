<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    @keyup.enter="setSearch"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              변경관리 목록 검색
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <br>
              <v-row>
                <v-col
                  cols="10"
                >
                  <v-col
                    cols="4"
                  >
                    <v-text-field
                      v-model="code"
                      class="purple-input"
                      label="발행일련번호"
                    />
                  </v-col>
                  <v-col
                    v-if="accountid == 99"
                    cols="2"
                  >
                    <v-text-field
                      v-model="jibucd"
                      class="purple-input"
                      label="지부코드"
                    />
                  </v-col>
                  <v-col
                    v-if="accountid == 99"
                    cols="3"
                  >
                    <v-text-field
                      v-model="name"
                      class="purple-input"
                      label="등록자명"
                      maxlength="10"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    />
                  </v-col>
                  <v-col
                    cols="4"
                  >
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="검색 시작날짜"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          class="purple-input"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="date"
                        locale="ko-KR"
                        @input="menu = false"
                      />
                    </v-menu>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date2"
                          label="검색 종료날짜"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          class="purple-input"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="date2"
                        locale="ko-KR"
                        @input="menu2 = false"
                      />
                    </v-menu>
                  </v-col>
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-1"
                    @click="setSearch()"
                  >
                    검색
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
        <base-material-card>
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              변경관리 목록
            </div>
          </template>

          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              @click:row="handleClick"
            >
              <template v-slot:item.action="{ item }">
                <v-btn
                  v-if="accountid == 99"
                  class="mr-1"
                  small
                  @click.stop="deletePrint(item)"
                >
                  삭제
                </v-btn>
              </template>
            </v-data-table>
            <v-card-text>
              <v-btn
                class="mr-1 float-right green"
                small
                @click.stop="excelPrint(items)"
              >
                엑셀출력
              </v-btn>
            </v-card-text>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog2"
      max-width="1200"
    >
      <v-card>
        <v-card-title>
          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="setModalClose()"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-spacer />
        <br>
        <v-spacer />
        <v-row>
          <v-col
            cols="12"
          >
            <base-material-card>
              <template v-slot:heading>
                <div class="text-h3 font-weight-light">
                  운송사업변경관리
                </div>
              </template>

              <v-form>
                <v-container class="py-0">
                  <br>
                  <v-row>
                    <v-col
                      cols="4"
                    >
                      <v-text-field
                        v-model="id"
                        class="purple-input"
                        label="발행일련번호"
                        disabled
                      />
                    </v-col>

                    <v-col
                      cols="4"
                    >
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="modaldate"
                            label="발행일자"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            class="purple-input"
                            :disabled="!canmodify"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="modaldate"
                          locale="ko-KR"
                          @input="menu3 = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <v-text-field
                        v-model="modalname"
                        class="purple-input"
                        label="성명"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col
                      cols="3"
                    >
                      <v-text-field
                        v-model="idfnum"
                        label="주민등록번호"
                        class="purple-input"
                        maxlength="14"
                        oninput="javascript:
                          if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);
                          if (this.value.length > 6 ) {
                            this.value = this.value.replace('-','');
                            this.value = this.value.slice(0, 6) + '-' + this.value.slice(6, this.maxLength);
                          }
                          "
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col
                      cols="1"
                    >
                      <v-text-field
                        label="경남"
                        disabled
                        class="purple-input"
                      />
                    </v-col>

                    <v-col
                      cols="2"
                    >
                      <v-text-field
                        v-model="carnum1"
                        label="차량 번호"
                        class="purple-input"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col
                      cols="1"
                    >
                      <v-text-field
                        label="바"
                        disabled
                        class="purple-input"
                      />
                    </v-col>

                    <v-col
                      cols="2"
                    >
                      <v-text-field
                        v-model="carnum2"
                        label="차량 번호"
                        class="purple-input"
                        :disabled="!canmodify"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </base-material-card>
            <base-material-card>
              <template v-slot:heading>
                <div class="text-h3 font-weight-light">
                  변경내역
                </div>
              </template>

              <v-form>
                <v-container class="py-0">
                  <br>
                  <v-row>
                    <v-col
                      cols="2"
                    >
                      <v-text-field
                        label="변경사유"
                        disabled
                        class="purple-input"
                      />
                    </v-col>
                    <br>
                    <v-col
                      cols="10"
                    >
                      <v-radio-group
                        v-model="row"
                        row
                        :disabled="!canmodify"
                      >
                        <v-radio
                          label="주소"
                          value="1"
                        />
                        <v-radio
                          label="주사무소"
                          value="2"
                        />
                        <v-radio
                          label="차종"
                          value="3"
                        />
                      </v-radio-group>
                    </v-col>

                    <v-col
                      cols="5"
                    >
                      <v-text-field
                        v-model="befpost"
                        label="변경전주소"
                        class="purple-input"
                        autocomplete="off"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col
                      cols="1"
                      class="text-right"
                    >
                      <v-btn
                        color="success"
                        class="mr-0"
                        :disabled="!canmodify"
                        @click="searchAdd(1)"
                      >
                        검색
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="5"
                    >
                      <v-text-field
                        v-model="aftpost"
                        class="purple-input"
                        label="변경후주소"
                        autocomplete="off"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col
                      cols="1"
                      class="text-right"
                    >
                      <v-btn
                        color="success"
                        class="mr-0"
                        :disabled="!canmodify"
                        @click="searchAdd(2)"
                      >
                        검색
                      </v-btn>
                    </v-col>

                    <v-col cols="5">
                      <v-text-field
                        v-model="befcarage"
                        class="purple-input"
                        label="변경전주사무소"
                        autocomplete="off"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col
                      cols="1"
                      class="text-right"
                    >
                      <v-btn
                        color="success"
                        class="mr-0"
                        :disabled="!canmodify"
                        @click="searchAdd(3)"
                      >
                        검색
                      </v-btn>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="aftcarage"
                        class="purple-input"
                        label="변경후주사무소"
                        autocomplete="off"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col
                      cols="1"
                      class="text-right"
                    >
                      <v-btn
                        color="success"
                        class="mr-0"
                        :disabled="!canmodify"
                        @click="searchAdd(4)"
                      >
                        검색
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="befcar"
                        :items="cars"
                        class="purple-input"
                        label="변경전차종"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="aftcar"
                        :items="cars"
                        class="purple-input"
                        label="변경후차종"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="befcarnum"
                        class="purple-input"
                        label="변경전차대번호"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="aftcarnum"
                        class="purple-input"
                        label="변경후차대번호"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="befcaryear"
                        class="purple-input"
                        label="변경전년식"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="aftcaryear"
                        class="purple-input"
                        label="변경후년식"
                        :disabled="!canmodify"
                      />
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <v-text-field
                        v-if="row == 3"
                        label="차량처리"
                        disabled
                        class="purple-input"
                      />
                    </v-col>
                    <br>
                    <v-col
                      cols="10"
                    >
                      <v-radio-group
                        v-if="row == 3"
                        v-model="row2"
                        row
                        :disabled="!canmodify"
                      >
                        <v-radio
                          label="자가용부활"
                          value="1"
                        />
                        <v-radio
                          label="차량폐차"
                          value="2"
                        />
                        <v-radio
                          label="차량수출"
                          value="3"
                        />
                        <v-radio
                          label="사업용"
                          value="4"
                        />
                        <v-radio
                          label="상속"
                          value="5"
                        />
                      </v-radio-group>
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        color="success"
                        class="mr-0"
                        :loading="progress"
                        @click="print"
                      >
                        출력
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </base-material-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <base-material-snackbar
      v-model="snackbar"
      type="error"
      v-bind="{
        ['top']: true,
        ['right']: true
      }"
    >
      {{ errormessage }}
    </base-material-snackbar>
    <base-material-snackbar
      v-model="snackbar2"
      type="success"
      v-bind="{
        ['top']: true,
        ['right']: true
      }"
    >
      {{ successmessage }}
    </base-material-snackbar>
  </v-container>
</template>
<script src="print.js"></script>

<script>
  import axios from 'axios'
  import print from 'print-js'
  import VueCookies from 'vue-cookies'
  export default {
    name: 'DashboardDashboard',
    data: () => ({
      selected: false,
      progress: false,
      accountid: 0, // 추후수정
      author: '',
      menu: '',
      menu2: '',
      menu3: '',
      jibucd: '',
      code: '',
      name: '',
      lastcode: 0,
      dialog2: false,
      dialog3: false,
      modalcode: '',
      modalname: '',
      modalcode2: '',
      modalname2: '',
      modaldate: '',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000 - (60000 * 24 * 30 * 30))).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      snackbar: false,
      snackbar2: false,
      errormessage: '',
      successmessage: '',
      row: '',
      row2: '',
      lastnum: 0,
      id: '',
      carnum1: '',
      carnum2: '',
      befpost: '',
      befcarage: '',
      befcar: '',
      befcarnum: '',
      befcaryear: '',
      aftpost: '',
      aftcarage: '',
      aftcar: '',
      aftcarnum: '',
      aftcaryear: '',
      idfnum: '',
      loginid: '',
      rowlist: ['', '주소', '주사무소', '차종'],
      cars: [],
      canmodify: false,
      alwayscanmodify: false,
      list: {
        0: false,
        1: false,
        2: false,
      },
      oldid: 0,
      headers: [
        {
          sortable: true,
          text: '코드',
          value: 'id',
        },
        {
          sortable: true,
          text: '차량번호',
          value: 'carnum_id',
        },
        {
          sortable: true,
          text: '성명',
          value: 'name',
          align: 'left',
        },
        {
          sortable: true,
          text: '등록자',
          value: 'colauthor',
          align: 'left',
        },
        {
          sortable: true,
          text: '구분',
          value: 'colrow',
          align: 'left',
        },
        {
          sortable: true,
          text: '날짜',
          value: 'printdate',
          align: 'left',
        },
        {
          sortable: false,
          text: '',
          value: 'action',
          align: 'right',
        },
      ],
      items: [],
    }),
    mounted () {
      const _this = this
      this.accountid = VueCookies.get('jibu_cd').padStart(2, '0')
      if(this.accountid === '99' ) this.jibucd = ''
      else this.jibucd = this.accountid
      this.cauthor = VueCookies.get('user_nm')
      if(this.accountid === '99' ) this.name = ''
      else this.name = this.cauthor
      const lv = VueCookies.get('permlevel_cd')
      if(lv == 3 || this.accountid === '99') this.alwayscanmodify = true
      this.loginid = VueCookies.get('user_id')
      this.onLoad()
      axios.get('https://gnpta.com:3000/getcar', {}, { withCredentials: true })
        .then((response) => {
          const test2 = response.data.map((data, index) => {
            return data.car_nm
          })
          this.cars = test2
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      setModalClose () {
        this.dialog2 = false
        this.id = ''
        this.modaldate = ''
        this.modalname = ''
        this.idfnum = ''
        this.carnum1 = ''
        this.carnum2 = ''
        this.row = ''
        this.befpost = ''
        this.aftpost = ''
        this.befcarage = ''
        this.aftcarage = ''
        this.befcar = ''
        this.aftcar = ''
        this.befcarnum = ''
        this.aftcarnum = ''
        this.befcaryear = ''
        this.aftcaryear = ''
        this.row2 = ''
        this.author = ''
        this.canmodify = false
      },
      onLoad () {
        const _this = this
        axios.get('https://gnpta.com:3000/getprint', { params: { id: this.code, jibucd: this.jibucd, sdate: this.date, edate: this.date2 } }, { withCredentials: true })
          .then(function (response) {
            console.log(response)
            const test2 = response.data.map((data, index) => {
              return { id: data.print_cd, name: data.user_nm, printdate:  new Date((new Date(data.createtime).getTime() + (9 * 60 * 60 * 1000))).toISOString().substr(0, 19),carnum_id: '경남' + data.carnum1 + '바' + data.carnum2, colrow: _this.rowlist[data.changereason], colauthor: data.author }
            })
            _this.items = test2
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      async handleClick (item) {
        await axios.get('https://gnpta.com:3000/getprintdetail', { params: { id: item.id } }, { withCredentials: true })
          .then( (response) => {
            const res = response.data[0]
            console.log(res)
            this.id = res.print_cd
            this.modaldate = res.printtime
            this.modalname = res.user_nm
            this.idfnum = res.idfnum
            this.carnum1 = res.carnum1
            this.carnum2 = res.carnum2
            this.row = String(res.changereason)
            this.befpost = res.befpost
            this.aftpost = res.aftpost
            this.befcarage = res.befcarage
            this.aftcarage = res.aftcarage
            this.befcar = res.befcar
            this.aftcar = res.aftcar
            this.befcarnum = res.befcarnum
            this.aftcarnum = res.aftcarnum
            this.befcaryear = res.befcaryear
            this.aftcaryear = res.aftcaryear
            this.row2 = String(res.carprocess)
            this.author = res.author

          })
          .catch(function (error) {
            console.log(error)
          })
          const _this = this
          axios.get('https://gnpta.com:3000/getuser', { params: { jibucode: this.id.split('-')[1]}}, { withCredentials: true })
          .then(function (response) {
            _this.jibuname = response.data[0].jibu_nm
          })
          .catch(function (error) {
            console.log(error)
          })

        if(this.alwayscanmodify){
          this.canmodify = true
        }else if(new Date(item.printdate).getTime() >  new Date().getTime()- (12 * 60 * 60 * 1000)){
          console.log('date',new Date(item.printdate).getTime() )
          console.log('date2',new Date().getTime()- (12 * 60 * 60 * 1000))
          this.canmodify = true
        }else{
          this.canmodify = false
        }

        this.dialog2 = true
        this.oldid = item.id
        this.modalcode2 = item.id
        this.modalname2 = item.name
      },
      setSearch () {
        const _this = this
        axios.get('https://gnpta.com:3000/getprint', { params: { id: this.code, jibucd: this.jibucd, sdate: this.date, edate: this.date2 } }, { withCredentials: true })
          .then(function (response) {
            console.log(response)
            const test2 = response.data.map((data, index) => {
              return { id: data.print_cd, name: data.user_nm, printdate:  new Date((new Date(data.createtime).getTime() + (9 * 60 * 60 * 1000))).toISOString().substr(0, 19),carnum_id: '경남' + data.carnum1 + '바' + data.carnum2, colrow: _this.rowlist[data.changereason], colauthor: data.author }
            })
            _this.items = test2
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      async print () {
        this.progress = true
        const _this = this
        axios.post('https://gnpta.com:3000/reprint', {
          id: this.id,
          name: this.modalname,
          carnum1: this.carnum1,
          carnum2: this.carnum2,
          befpost: this.befpost,
          befcarage: this.befcarage,
          befcar: this.befcar,
          befcarnum: this.befcarnum,
          befcaryear: this.befcaryear,
          aftpost: this.aftpost,
          aftcarage: this.aftcarage,
          aftcar: this.aftcar,
          aftcarnum: this.aftcarnum,
          aftcaryear: this.aftcaryear,
          date: this.modaldate,
          idfnum: this.idfnum,
          accountid: this.id.split('-')[1],
          changereason: this.row,
          carprocess: this.row2,
          jibuname: _this.jibuname,
          author : _this.author,

        }, { withCredentials: true , responseType: 'arraybuffer'})
          .then(function (response) {

            if (response.status === 200) {
              var pdfFile = new Blob([response.data], {
                type: 'application/pdf',
              })
              console.log(pdfFile)
              var pdfUrl = URL.createObjectURL(pdfFile)

              printJS({ printable: [pdfUrl],
                        type:'image',
                        header:false,
                        showModal: true, // Optional
                        modalMessage: '프린트 중입니다 ...', // Optional
                        imageStyle: 'width:99%;heigh:99%;margin-left:0px;margin-top:0px;'
                      })
            }
          }).then(async ()=>{
            if(_this.row == 3){
              await _this.print_car()
            }else{
              _this.setModalClose()
              _this.progress = false
            }
          })
          .catch(function (error) {
            _this.progress = false
            _this.errormessage = '인쇄 도중 오류가 발생하였습니다. '
            _this.snackbar = true
            console.log(error)
          })
      },
      async print_car(){
        this.progress = true
        const _this = this
        axios.post('https://gnpta.com:3000/print_car', {
          id: _this.id,
          name: _this.modalname,
          carnum1: _this.carnum1,
          carnum2: _this.carnum2,
          befpost: _this.befpost,
          befcarage: _this.befcarage,
          befcar: _this.befcar,
          befcarnum: _this.befcarnum,
          befcaryear: _this.befcaryear,
          aftpost: _this.aftpost,
          aftcarage: _this.aftcarage,
          aftcar: _this.aftcar,
          aftcarnum: _this.aftcarnum,
          aftcaryear: _this.aftcaryear,
          date: _this.modaldate,
          idfnum: _this.idfnum,
          accountid: _this.id.split('-')[1],
          changereason: _this.row,
          carprocess: _this.row2,
          jibuname: _this.jibuname,


        }, { withCredentials: true , responseType: 'arraybuffer'})
          .then(function (response) {

            if (response.status === 200) {
              var pdfFile = new Blob([response.data], {
                type: 'application/pdf',
              })
              console.log(pdfFile)
              var pdfUrl = URL.createObjectURL(pdfFile)

              printJS({ printable: [pdfUrl],
                        type:'image',
                        header:false,
                        showModal: true, // Optional
                        modalMessage: '프린트 중입니다 ...', // Optional
                        //imageStyle: 'width:106%;margin-left:-20px;margin-top:20px;'
                        imageStyle: 'width:99%;heigh:99%;margin-left:-5px;margin-top:0px;'
                      })
            }
          }).then(()=>{
            _this.setModalClose()
            _this.progress = false
          })
          .catch(function (error) {
            _this.progress = false
            _this.errormessage = '인쇄 도중 오류가 발생하였습니다. '
            _this.snackbar = true
            console.log(error)
          })
      },
      async searchAdd(val){
        new window.daum.Postcode({
          oncomplete: (data) => {
            let fullRoadAddr = data.roadAddress;
            let extraRoadAddr = '';
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
              extraRoadAddr += data.bname;
            }
            if(data.buildingName !== '' && data.apartment === 'Y'){
              extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            if(extraRoadAddr !== ''){
              extraRoadAddr = ' (' + extraRoadAddr + ')';
            }
            if(fullRoadAddr !== ''){
              fullRoadAddr += extraRoadAddr;
            }
            if(val === 1){
              this.befpost = fullRoadAddr + '(' + data.zonecode + ')'
            }else if(val === 2) {
              this.aftpost = fullRoadAddr + '(' + data.zonecode + ')'
            }else if(val === 3) {
              this.befcarage = fullRoadAddr + '(' + data.zonecode + ')'
            }else if(val === 4) {
              this.aftcarage = fullRoadAddr + '(' + data.zonecode + ')'
            }
          }
        }).open();
      },
      async deletePrint (item) {
        const _this = this
        const r = await this.$dialog.prompt({ title: '정말 삭제 하시겠습니까?', text: '비밀번호 입력',textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'password',
        }})
        if (r) {

          axios.post('https://gnpta.com:3000/logincheck', { login_id: this.loginid, pass: r}, { withCredentials: true })
          .then(function (response) {
            axios.post('https://gnpta.com:3000/delprint', { id: item.id }, { withCredentials: true })
            .then(function (response2) {
              _this.onLoad()
            })
          })
          .catch(({ msg }) => (alert('비밀번호를 확인해주세요')))
        }
      },
      async excelPrint(items){

        if(items){
          //axios.get('https://gnpta.com:3000/excelprint', {id: this.code, jibucd: this.jibucd, name: this.name, sdate: this.date, edate: this.date2,items: items}, { withCredentials: true ,responseType: 'blob'})
          axios.post('https://gnpta.com:3000/excelprint', { id: this.code, jibucd: this.jibucd, sdate: this.date, edate: this.date2 } , { withCredentials: true ,responseType: 'blob'})
          .then((response) => {
            if (response.status === 200) {
              this.successmessage = '다운로드 중입니다.'
              this.snackbar2 = true
              //let blob = new Blob([response.data], { type: response.headers['content-type'] })
              const blob = window.URL.createObjectURL(new Blob([response.data]));

              if (window.navigator.msSaveOrOpenBlob) { // IE 10+
                window.navigator.msSaveOrOpenBlob(blob, 'exceldown.xlsx')
              } else { // not IE

                const link = document.createElement('a');
                link.href = blob;
                link.setAttribute('download', 'exceldown.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
              }
            }
          })
          .catch(({ e }) => {
            this.errormessage = '프린트중 오류가 발생하였습니다.'
            this.snackbar = true
          })
        }
        console.log(items)
      },
    },

  }
</script>
